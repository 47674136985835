import { useEffect } from 'react'

import { sentryLogging } from 'sentry-utils/logging'

const allowedHostEndings = [
  'bluelagoon.com',
  'bluelagoon.dev',
  'bluelagoon.vercel.app',
  'localhost:3002',
]

/**
 * This hook is used to protect against reverse proxy attacks.
 * It checks if the current hostname ends with the allowed hostnames.
 * If it doesn't, it logs the event to Sentry and redirects the user to the main page.
 */

export const useReverseProxyProtection = () => {
  useEffect(() => {
    const isAllowed = allowedHostEndings.some(ending =>
      window?.location?.host?.endsWith(ending)
    )

    if (!isAllowed) {
      sentryLogging({
        team: 'team-frontend-infrastructure',
        message: `Reverse proxy attack detected. Hostname: ${window?.location?.hostname}`,
        extras: {
          hostname: window?.location?.hostname,
          url: window?.location?.href,
        },
      })

      window.location.href = 'https://www.bluelagoon.com'
    }
  }, [])
}
